import React, { useState, useEffect, Suspense } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from "reactstrap";
import { FaUserLock, FaUserCheck } from "react-icons/fa";
import Logo from "../../images/logo.png";
import { navigate } from "gatsby-link";

import { Link } from "gatsby";
import { AuthCheck } from "reactfire";
import Loader from "../Loader";
// import Loader from "react-loader-spinner";

const Header1 = ({ location }) => {
  const [isOpen, setIsOpen] = useState(false);
  //  const {isSignedIn, setSignedInState} = useContext(Context);
  const [isSignedIn, setIsSignedIn] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const signOut = () => {
    window.localStorage.setItem("isSignedIn", false);
    navigate("/Login", { state: { from: "signOut" } });
  };

  console.log("Header1: " + location);

  return (
    <div>
      {/* <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} 
      /> */}
      <header>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">
            <img src={Logo} alt="logo" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink href="/">HOME</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/OurStory">OUR STORY </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/Products">PRODUCTS</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/ContactUs">CONTACT</NavLink>
              </NavItem>
            </Nav>
            <Suspense fallback={<Loader />}>
              <AuthCheck
                fallback={
                  <div>
                    <NavbarText>
                      <a href="/Login">
                        {" "}
                        <FaUserLock size={16} color={"#808080"} /> Login
                      </a>
                    </NavbarText>
                    <NavbarText>
                      <a href="/Register" className="login_devider">
                        <FaUserCheck size={16} color={"#808080"} /> Register{" "}
                      </a>
                    </NavbarText>
                  </div>
                }
              >
                {" "}
                <div>
                  <NavbarText onClick={signOut}>
                    <div className="signoutBt">
                      <p>Log Out</p>
                    </div>
                  </NavbarText>
                </div>
              </AuthCheck>
            </Suspense>
          </Collapse>
        </Navbar>
      </header>
    </div>
  );

  // useEffect(() => {
  // location != undefined && location == "loggedIn" ? setIsSignedIn(true) : setIsSignedIn(false)

  // }, [location])

  // console.log("Header: "+JSON.stringify(location));
};

const Header = () => {
  return <Header1 />;
};

export default Header;
